import { Controller } from "stimulus"

import Rails from "@rails/ujs";

import jQuery from "jquery"
import "datatables.net-dt"

let $ = jQuery;
$.fn.dataTable.ext.classes.sWrapper = 'flex flex-col';
$.fn.dataTable.ext.classes.sPageButton = 'py-1.5 px-3 mr-1 text-sm font-medium focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 cursor-pointer';
$.fn.dataTable.ext.classes.sPageButtonActive = 'bg-primary-700 text-white';
$.fn.dataTable.ext.classes.sPaging = 'dataTables_paginate mt-2 text-gray-700 paging_';
$.fn.dataTable.ext.classes.sFilterInput = 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-blue-500 inline ml-2';
$.fn.dataTable.ext.classes.sLengthSelect = 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-blue-500 inline w-20 mx-1';

export default class extends Controller {
  static targets = ["doctor", "slot", "lang_code"]

  evaluate () {
    let dry = document.getElementById('dry').value
    let oil = document.getElementById('oil').value
    if (dry && dry >= 0 && oil && oil >= 0) {
      document.getElementById('evaluation_form').submit()
    } else {
      alert(window.locale == 'pl' ? 'Proszę określić miesięczną ilość suszu i ekstraktu dla badanego.' : 'Please specify the participant\'s quota')
    }
  }

  reject (event) {
    let confirmation = confirm(window.locale == 'pl' ? 'Czy na pewno chcesz odrzucić uczestnika? Tego nie da się cofnąć' : 'Are you sure you want to reject the participant? This cannot be undone')
    if (confirmation) {
      let id = event.target.dataset.id
      let reason = prompt(window.locale == 'pl' ? 'Powód odrzucenia:' : 'Reason for rejection:', window.locale == 'pl' ? 'nie podano powodu' : 'no reason supplied')
      if (reason === null) {
        return
      } else {
      Rails.ajax({
       type: "DELETE",
       url: `/doctor/evaluate/${id}?reason=${encodeURIComponent(reason)}&locale=${window.locale}`,
       success: function(data){
        root.innerHTML = data.body.innerHTML;
       }
      });}
    }
  }

  initialize () {
    window.root = this.doctorTarget;
  }

  connect () {

    try {
      if (this.lang_codeTarget.value == 'pl') var lang_url = '//cdn.datatables.net/plug-ins/1.12.1/i18n/pl.json';
    } catch (e) {
      var lang_url = '';
    }

    let dt_config = {
      dom: '<"flex justify-between items-center mb-2"lf>rt<"flex justify-between items-center mt-2"<"text-xs"i>p>',
      pagingType: "simple",
      stateSave: true,
      destroy: true,
      language: { url: lang_url },
      initComplete: function(settings, json) {
        // console.log('dataTable settings', settings);
        // console.log('dataTable json', json);
      }
    }

    $("#users_dt").dataTable(dt_config);

  }

  disconnect () {

    if (!$.fn.DataTable.isDataTable("#users_dt")) {
      $('#users_dt').siblings().remove();
    }

  }

  pop_up(e) {
    e.preventDefault()
    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000`;

    window.open(e.target.href, 'Select Participant', params);
  }

  highlightColumn(el) {
    document.getElementById(`time_${el.target.dataset.col}`).classList.toggle('text-green-500')
    document.getElementById(`day_${el.target.dataset.row}`).classList.toggle('text-green-500')
  }
  unhighlightColumn(el) {
    document.getElementById(`time_${el.target.dataset.col}`).classList.toggle('text-green-500')
    document.getElementById(`day_${el.target.dataset.row}`).classList.toggle('text-green-500')
  }
  setAvailable(el) {
    el.target.classList.toggle('bg-green-700')
    el.target.classList.toggle('bg-opacity-50')
  }
}
