import { Controller } from "stimulus"

import Rails from "@rails/ujs";

import jQuery from "jquery"
import "datatables.net-dt"
import {BrowserQRCodeReader,} from "@zxing/browser";

let $ = jQuery;
$.fn.dataTable.ext.classes.sWrapper = 'flex flex-col';
$.fn.dataTable.ext.classes.sPageButton = 'py-1.5 px-3 mr-1 text-sm font-medium focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 cursor-pointer';
$.fn.dataTable.ext.classes.sPageButtonActive = 'bg-primary-700 text-white';
$.fn.dataTable.ext.classes.sPaging = 'dataTables_paginate mt-2 text-gray-700 paging_';
$.fn.dataTable.ext.classes.sFilterInput = 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-blue-500 inline ml-2';
$.fn.dataTable.ext.classes.sLengthSelect = 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-blue-500 inline w-20 mx-1';

export default class extends Controller {
  static targets = ["lang_code", "admin"]

  verify (event) {
    console.log(event)
    let path = event.target.dataset.path
    Rails.ajax({
        type: "POST",
        url: path
    });
  }

  ban (event) {
    let confirmation = confirm(window.locale == 'pl' ? 'Czy na pewno chcesz zbanować uczestnika? Tego nie da się cofnąć' : 'Are you sure you want to ban the participant? This cannot be undone')
    if (confirmation) {
      let id = event.target.dataset.id
      let reason = prompt(window.locale == 'pl' ? 'Powód bana:' : 'Reason for ban:', window.locale == 'pl' ? 'nie podano powodu' : 'no reason supplied')
      if (reason === null) {
        return;
      } else {
      Rails.ajax({
       type: "DELETE",
       url: `/delete_user/${id}?reason=${encodeURIComponent(reason)}&locale=${window.locale}`,
       success: function(data){
        root.innerHTML = data.body.innerHTML;
       }
      });}
    }
  }

  form () {
    const children = document.getElementById("select_options").children;
    Array.from(children).forEach(child =>{
      child.disabled = true;
      child.classList.add('hidden');
    });
    var searchBy = document.getElementById("search_by").value
    if (searchBy == '') {
      return;
    } else{
      if (searchBy == 'email' || searchBy == 'last_name' || searchBy == 'id' || searchBy == 'contact_number' || searchBy == 'legitimation_number') {
        var input = document.getElementById("text");
        input.classList.remove('hidden');
        input.disabled = false;
      } else if (searchBy == 'province') {
        var textLabel = document.getElementById("city_label");
        var textInput = document.getElementById("city");
        var select = document.getElementById(searchBy);
        textLabel.classList.remove('hidden');
        textInput.classList.remove('hidden');
        textInput.disabled = false;
        select.classList.remove('hidden');
        select.disabled = false;
      } else {
        var select = document.getElementById(searchBy);
        select.classList.remove('hidden');
        select.disabled = false;
      };}
  }

  linksClassChange(event){
    const children = document.getElementById("links").children;
    Array.from(children).forEach(child =>{
      child.classList.remove('bg-gray-300');
      child.classList.add('bg-gray-100');
    });
    event.currentTarget.classList.add('bg-gray-300');
  }

  pickSurveyType () {
    var quartalDiv = document.getElementById("quarter_div")
    quartalDiv.classList.add('hidden');
    var surveyType = document.getElementById("survey_type").value
    if (surveyType == 'medical') {
      quartalDiv.classList.remove('hidden');
      document.getElementById("quarter").disabled = false;
    } else {
      quartalDiv.classList.add('hidden');
      document.getElementById("quarter").disabled = true;
    };
  }

  initialize () {}

  connect () {
    try {
      if (this.lang_codeTarget.value == 'pl') var lang_url = '//cdn.datatables.net/plug-ins/1.12.1/i18n/pl.json';
    } catch (e) {
      var lang_url = '';
    }

    let dt_config = {
      dom: '<"flex justify-between items-center mb-2"lf>rt<"flex justify-between items-center mt-2"<"text-xs"i>p>',
      stateSave: true,
      language: { url: lang_url },
      initComplete: function(settings, json) {
        // console.log('dataTable settings', settings);
        // console.log('dataTable json', json);
      }
    }

    $("#participants_dt").dataTable(dt_config);
  }

  disconnect () {
    if (!$.fn.DataTable.isDataTable("#participants_dt")) {
      $('#participants_dt').siblings().remove();
    }
  }

  pop_up(e) {
    e.preventDefault()
    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1200,height=800`;

    window.open(e.target.href, 'Select Participant', params);
  }

  droughtCouponScanner () {
    const participantId = document.getElementById('participant_id').value;
    let referrerId = prompt(window.locale == 'pl' ? 'ID użytkownika' : 'User ID', window.locale == 'pl' ? 'ID użytkownika' : 'user ID')
    const codeReader = new BrowserQRCodeReader();
    codeReader
      .decodeOnceFromVideoDevice(undefined, "videoElm")
      .then((result) => {
        this.stopScanner()
        Rails.ajax({
          type: "post",
          url: `/admin_dashboard/read_coupon?code=${result}&referrer_id=${referrerId}&participant_id=${participantId}`,
          success: function(data){
            root.innerHTML = data.body.innerHTML;
          }
          });
        });
      }

  stopScanner () {
    const videoElm = document.getElementById("videoElm");

    if (videoElm) {
      videoElm.srcObject
        .getVideoTracks()
        .forEach((track) => track.stop());
    }
  }
}
