import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "map", "lat", "lng", "title" ]

  connect() {
    if (typeof(google) != "undefined") {
      this.initMap()
    }
  }
  initMap() {
    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(52.10, 19.30),
      zoom: 6
    })
    var count;
    var locations = this.initLocations();
    for (count = 0; count < locations.length; count++) {  
      this.marker = new google.maps.Marker({
        position: new google.maps.LatLng(locations[count][1], locations[count][2]),
        map: this.map,
        title: locations[count][0]
        });
    }
  }
  initLocations() {
    let locations2 = [];
    var count2;
    for (count2 = 0; count2 < this.titleTargets.length; count2++) {
      locations2.push([this.titleTargets[count2].innerText, this.latTargets[count2].innerText, this.lngTargets[count2].innerText])
    }
    return locations2;
  }
}
