import { Controller } from "stimulus"

import Rails from "@rails/ujs";
import {
  BrowserQRCodeReader,
} from "@zxing/browser";

export default class extends Controller {
  static targets = ["participant", "url"]

  updateSurvey (e) {
    let confirmation = confirm(window.locale == 'pl' ? 'Czy na pewno nic się nie zmieniło od poprzedniego miesiąca?' : 'Are you sure nothing has changed since last month?')
    if (confirmation) {
    const answers = JSON.parse(e.target.dataset.answers)
    answers.forEach(answer =>{
      document.getElementById(answer[0]).value = answer[1];
    });} else {
      return
    }
  }

  copy () {
    const url = this.urlTarget.value
    const input = document.createElement('textarea');

    input.innerHTML = url;
    document.body.appendChild(input);
    input.select();

    try {
      var result = document.execCommand('copy');
      alert(`${window.locale == 'pl' ? 'URL skopiowany do schowka' : 'URL copied to clipboard'}`)
    } catch(e) {
      console.error(e);
    } finally {
      input.remove()
    }
  }

  toggle(e) {
    e.preventDefault()
    e.target.nextElementSibling.classList.toggle('hidden')
  }
  initialize () {}

  connect () {}

  disconnect () {}

  annualFeeCouponScanner () {
    const codeReader = new BrowserQRCodeReader();

    codeReader
      .decodeOnceFromVideoDevice(undefined, "videoElm")
      .then((result) => {
        this.stopScanner()
        Rails.ajax({
          type: "post",
          url: `${window.locale == 'pl' ? '' : window.locale}/participant/pay_annual_with_coupon?code=${result}`,
          success: function(data){
            root.innerHTML = data.body.innerHTML;
          }
          });
        });
      }

  stopScanner () {
    const videoElm = document.getElementById("videoElm");

    if (videoElm) {
      videoElm.srcObject
        .getVideoTracks()
        .forEach((track) => track.stop());
    }
  }
}
