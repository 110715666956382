import { Controller } from "stimulus"

import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["avatarDrop", "input"]

  avatarUpload(e){
    this.inputTarget.click()
  }

  avatarReplace(e){
    alert(e.currentTarget.dataset.dataWarning)
    let drop = this.avatarDropTargets
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      var reader = new FileReader();

      reader.onload = function(e) {
        drop.forEach(drop => drop.getElementsByTagName('img')[0].src = e.target.result)
      }

      reader.readAsDataURL(e.currentTarget.files[0]); // convert to base64 string
    }
  }

  changeFirstName (e) {
    document.getElementById('preview_first_name').innerHTML = e.target.value
  }

  changeLastName (e) {
    document.getElementById('preview_last_name').innerHTML = e.target.value
  }

  changePesel (e) {
    document.getElementById('preview_pesel').innerHTML = e.target.value
  }

  initialize () {}

  connect () {}

  disconnect () {}
}
