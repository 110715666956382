import { Controller } from "stimulus"

import Rails from "@rails/ujs";

export default class extends Controller {

  remove() {
    this.element.replaceChildren()
  }

}
