import { Controller } from "stimulus"

import Rails from "@rails/ujs";
import {
  BrowserDatamatrixCodeReader,
  BrowserQRCodeReader,
} from "@zxing/browser";

export default class extends Controller {
  static targets = ["dispensary", "transfer", "start", "end", "url"]
  static values = {
     product: Number,
      orderId: Number,
      serialNumber: String,
      batchId: Number
    }
  selectedProducts = []

  searchScreen(){
    Rails.ajax({
     type: "get",
     url: `${window.locale == 'pl' ? '' : window.locale}/dispensary_search`,
     success: function(data){
      root.innerHTML = data.body.innerHTML;
     }
    });
  }

  copy () {
    const url = this.urlTarget
    url.select()
    url.setSelectionRange(0, 99999)
    document.execCommand("copy")
    alert(`${window.locale == 'pl' ? 'URL skopiowany' : 'URL copied'}`)
  }

  startScanner () {
    const codeReader = new BrowserQRCodeReader();

    codeReader
      .decodeOnceFromVideoDevice(undefined, "videoElm")
      .then((result) => {
        this.stopScanner()
        Rails.ajax({
          type: "get",
          url: `${window.locale == 'pl' ? '' : window.locale}/dispensary_participant?code=${result}`,
          success: function(data){
            root.innerHTML = data.body.innerHTML;
          }
          });
        });
      }

  stopScanner () {
    const videoElm = document.getElementById("videoElm");

    if (videoElm) {
      videoElm.srcObject
        .getVideoTracks()
        .forEach((track) => track.stop());
    }
  }

  participantScreenDemand () {
    const generated = event.target.dataset.generated;
    const code = event.target.dataset.code;
    Rails.ajax({
     type: "get",
     url: `dispensary_participant?code=${code}&locale=${window.locale}&generated=${generated}`,
     success: function(data){
      root.innerHTML = data.body.innerHTML;
     }
    });
  }

  participantScreen () {
    const code = document.getElementById("code").value;
    Rails.ajax({
     type: "get",
     url: `dispensary_participant?code=${code}&locale=${window.locale}`,
     success: function(data){
      root.innerHTML = data.body.innerHTML;
     }
    });
  }

  productScreen(){
    Rails.ajax({
     type: "get",
     url: `/product_choice?locale=${window.locale}`,
     success: function(data){
      root.innerHTML = data.body.innerHTML;
     }
    });
  }

  startScannerProducts () {
    const codeReader = new BrowserDatamatrixCodeReader();

    codeReader.decodeOnceFromVideoDevice(undefined, 'videoElm').then((result) => {
      this.stopScanner();
      Rails.ajax({
        type: "get",
        url: `/product_choice?locale=${window.locale}&qr_code_string=${result}`,
        success: function (data) {
          root.innerHTML = data.body.innerHTML;
        },
      });
    })
  }

  productScreenNumbers(){
    var number = document.getElementById("code").value;
    // console.log(number);
    // this.selectedProducts.push(number);
    // const selectedProductArg = this.selectedProducts.join("&arr[]=");
    // console.log(selectedProductArg);
    // window.history.replaceState(null, null, `?arr=[]&arr=${selectedProductArg}`);
    // const serialNumbers = document.getElementById('serial_numbers')
    // const textnode = document.createTextNode(number);
    // serialNumbers.appendChild(textnode);
    Rails.ajax({
     type: "get",
     url: `/product_choice?locale=${window.locale}&serial_number=${number}`,
     success: function(data){
      root.innerHTML = data.body.innerHTML;
     }
    });
  }

  transferScreen (e) {
    Rails.ajax({
     type: "get",
     url: `/transfer?product=${e.currentTarget.dataset.product}&locale=${window.locale}`,
     success: function(data){
      root.innerHTML = data.body.innerHTML;
     }
    });
  }

  finalizeTransfer() {
    Rails.ajax({
      type: "post",
      url: `/finalize_transfer?locale=${window.locale}`,
      success: function (data) {
        root.innerHTML = data.body.innerHTML;
        toastr.success(window.locale == 'pl' ? 'Produkty zostały przypisane do uczestnika' : 'Products has been assigned to participant', window.locale == 'pl' ? 'Sukces' : 'Success')
      },
        error: function(data) {
          toastr.error(JSON.parse(data.body.innerHTML).message)
        }
    });
  }

  orderScreen(e){
    Rails.ajax({
     type: "get",
     url: `/order?material=${e.currentTarget.dataset.material}&locale=${window.locale}`,
     success: function(data){
      root.innerHTML = data.body.innerHTML;
     }
    });
  }

  filterTransfer(){
    let start = this.startTarget.value
    let end = this.endTarget.value
    window.location= `/dispensary/transfers?${start ? 'start='+start+'&' : ''}${end ? 'end='+end : ''+'&'}&locale=${window.locale}`
  }

  initialize () {
    window.root = this.dispensaryTarget;
  }

  connect () {}

  disconnect () {}

  takeUpProduct(){
    let number = event.target.dataset.serial_number
    Rails.ajax({
      type: "POST",
      url: `demands/take_up_product?serial_number=${number}`,
      success: function(data){
        root.innerHTML = data.body.innerHTML;
       }
    })
  }

  returningBoxes(){
    Rails.ajax({
      type: "GET",
      url: `dispensary/returning_boxes?locale=${window.locale}`,
      success: function(data){
        root.innerHTML = data.body.innerHTML;
       }
    })
  }

  returningBoxesNumbers(){
    const code = document.getElementById("serial_number").value;
    console.log(code);
    Rails.ajax({
      type: "GET",
      url: `dispensary/returning_boxes?locale=${window.locale}&qr_code_string=${code}`,
      success: function(data){
        root.innerHTML = data.body.innerHTML;
       }
    })
  }

  returningBoxesFee(){
    // var number = document.getElementById("code").value;
    const number = this.serialNumberValue
    console.log(number);
    Rails.ajax({
      type: "GET",
      url: `dispensary/returning_boxes?locale=${window.locale}&lost_box=${number}`,
      success: function(data){
        root.innerHTML = data.body.innerHTML;
       }
    })
  }

  startScannerReturnBoxes () {
    const codeReader = new BrowserDatamatrixCodeReader();

    codeReader.decodeOnceFromVideoDevice(undefined, 'videoElm').then((result) => {
        this.stopScanner();
        Rails.ajax({
          type: "get",
          url: `/dispensary/returning_boxes?locale=${window.locale}&qr_code_string=${result}`,
          success: function (data) {
            root.innerHTML = data.body.innerHTML;
          },
        });
      }
    );
  }

  cancelRelease(){
    Rails.ajax({
      type: "POST",
      url: `dispensary/cancel_release`,
      success: function(data){
        root.innerHTML = data.body.innerHTML;
       }
    })
  }

  startScannerTakeUpProduct () {
    var order_id = document.getElementById("code").value;
    const codeReader = new BrowserDatamatrixCodeReader();
    codeReader.decodeOnceFromVideoDevice(undefined, 'videoElm').then((result) => {
        this.stopScanner();
        Rails.ajax({
          type: "post",
          url: `/demands/take_up_product?locale=${window.locale}&qr_code_string=${result}&order_id=${order_id}`,
          success: function (data) {
            root.innerHTML = data.body.innerHTML;
          },
        });
      }
    );
  }

  batchChangeShipment () {
    const orderId = this.orderIdValue
    const codeReader = new BrowserDatamatrixCodeReader();
    codeReader.decodeOnceFromVideoDevice(undefined, 'videoElm').then((result) => {
        this.stopScanner();
        Rails.ajax({
          type: "post",
          url: `/dispensary/change_batch_shipment?locale=${window.locale}&qr_code_string=${result}&order_id=${orderId}`,
          success: function (data) {
            root.innerHTML = data.body.innerHTML;
          },
        });
      }
    );
  }

  toggle(e) {
    var id = e.currentTarget.dataset.id
    document.getElementById(id).classList.toggle('hidden')
  }

  batchDivide () {
    const batchId = this.batchIdValue
    const codeReader = new BrowserDatamatrixCodeReader();
    codeReader.decodeOnceFromVideoDevice(undefined, 'videoElm').then((result) => {
        this.stopScanner();
        Rails.ajax({
          type: "post",
          url: `/dispensary/divide_batch?locale=${window.locale}&qr_code_string=${result}${batchId === 0 ? "" : `&batch_id=${batchId}`}`,
          success: function (data) {
            root.innerHTML = data.body.innerHTML;
          },
        });
      }
    );
  }
  submitByEnter (event) {
    if (event.keyCode == 13) {
      document.getElementById('submitButton').click();
    }
  }
}
