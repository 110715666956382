// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import {Turbo} from "@hotwired/turbo-rails";
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import 'stylesheets/application.scss';
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "chartkick/chart.js"

// import Swiper JS
import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';

SwiperCore.use([Navigation, Pagination]);

var mySwiper = new Swiper('.swiper-container', {
  // Optional parameters
  autoHeight: true,
  calculateHeight: true,
  direction: 'horizontal',
  loop: true,

  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
  },
});

// Animation on scroll
import AOS from 'aos';
import 'aos/dist/aos.css';

Rails.start();

Turbo.start();

ActiveStorage.start();

global.toastr = require('toastr');

window.Trix = require("trix");

require('@rails/actiontext');

import 'controllers';

// Google Maps callback
window.initMap = function (...args) {
  const event = document.createEvent('Events');
  event.initEvent('google-maps-callback', true, true);
  event.args = args;
  window.dispatchEvent(event);
};

document.addEventListener('turbo:load', () => {
  flatpickr(".datepicker", {
    enableTime: true,
    minDate: "today"
  })

  AOS.init({ duration: 1200, offset: 300 });

  var mySwiper = new Swiper('.swiper-container', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,

    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
    },
  });
});
