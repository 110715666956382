import { Controller } from "stimulus"

import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["supplier"]

  reject (event) {
    let confirmation = confirm(window.locale == 'pl' ? 'Czy na pewno chcesz odrzucić zamówienie? Tego nie da się cofnąć' : 'Are you sure you want to reject the demand? This cannot be undone')
    if (confirmation) {
      let id = event.target.dataset.id
      let deleted = event.target.dataset.delete
      let reason = prompt(window.locale == 'pl' ? 'Powód odrzucenia:' : 'Reason for rejection:', window.locale == 'pl' ? 'nie podano powodu' : 'no reason supplied')
      if (deleted === 'true') {
      Rails.ajax({
       type: "DELETE",
       url: `/fulfill_demands/cancel_order/${id}?reason=${encodeURIComponent(reason)}&locale=${window.locale}`,
       success: function(data){
        root.innerHTML = data.body.innerHTML;
       }
      })}else {
        Rails.ajax({
       type: "POST",
       url: `/supplier/reject_order/${id}?reason=${encodeURIComponent(reason)}&locale=${window.locale}`,
       success: function(data){
        root.innerHTML = data.body.innerHTML;
       }
      })};
    }
  }
}