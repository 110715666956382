import { Controller } from "stimulus"

import Rails from "@rails/ujs";
import jQuery from "jquery"

import "jszip"
import "pdfmake"
import "datatables.net-dt"
import "datatables.net-buttons-dt"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"

let $ = jQuery;
$.fn.dataTable.ext.classes.sWrapper = 'flex flex-col';
$.fn.dataTable.ext.classes.sPageButton = 'py-1.5 px-3 mr-1 text-sm font-medium focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 cursor-pointer';
$.fn.dataTable.ext.classes.sPageButtonActive = 'bg-primary-700 text-white';
$.fn.dataTable.ext.classes.sPaging = 'dataTables_paginate mt-2 text-gray-700 paging_';
$.fn.dataTable.ext.classes.sFilterInput = 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-blue-500 inline ml-2';
$.fn.dataTable.ext.classes.sLengthSelect = 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-blue-500 inline w-20 mx-1';

export default class extends Controller {
  static targets = ["lang_code"]

  initialize () {
    const lang_code = this.lang_codeTarget.value;
    var lang_url = '';

    if (lang_code == 'pl') lang_url = '//cdn.datatables.net/plug-ins/1.12.1/i18n/pl.json';

    var dt_config = {
      dom: '<"flex justify-between items-center mb-2"Bf>rt<"flex justify-between items-center mt-2"ip>',
      buttons: [
        {
          extend: 'copy',
          className: $.fn.dataTable.ext.classes.sPageButton,
          exportOptions: {
            format: {
              body: function ( data, row, column, node ) {
                var div = document.createElement('div');
                div.innerHTML = data;
                return div.innerText
              }
            },
            modifier: {
              page: 'all',
              search: 'none'
            }
          }
        },
        {
          extend: 'csv',
          className: $.fn.dataTable.ext.classes.sPageButton,
          exportOptions: {
            format: {
              body: function ( data, row, column, node ) {
                var div = document.createElement('div');
                div.innerHTML = data;
                return div.innerText
              }
            },
            modifier: {
              page: 'all',
              search: 'none'
            }
          }
        },
        {
          extend: 'print',
          className: $.fn.dataTable.ext.classes.sPageButton,
          exportOptions: {
            format: {
              body: function ( data, row, column, node ) {
                var div = document.createElement('div');
                div.innerHTML = data;
                return div.innerText
              }
            },
            modifier: {
              page: 'all',
              search: 'none'
            }
          }
        },
        // Something is not worky worky here
        // started_statistic:1 Uncaught (in promise) File 'Roboto-Regular.ttf' not found in virtual file system
        // {extend: 'pdf', className: $.fn.dataTable.ext.classes.sPageButton},
      ],
      language: { url: lang_url },
      initComplete: function(settings, json) {
        console.log('dataTable settings', settings);
        $(settings.nTable).removeClass('hidden');
        $('#loader').remove();
        // console.log('dataTable json', json);
      }
    }

    var tables = $(".pop_portal_tables").dataTable(dt_config);
  }

  user_screen(e) {
    e.preventDefault()
    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000`;

    window.open(e.target.href, 'Select Participant', params);
  }

  connect () {}

  disconnect () {}
}
